<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card class="card-lock card-white text-center">
        <br />
        <form @submit.prevent class="login">
          <base-input
            placeholder="Username" autocomplete="username"
            addon-left-icon="tim-icons icon-single-02"
            v-model="username" :readonly="readonly">
          </base-input>
          <base-input
            type="password" autocomplete="current-password"
            addon-left-icon="tim-icons icon-key-25"
            placeholder="Password" v-model="password">
          </base-input>
          <base-input v-if="q.a !== undefined"
            type="password" autocomplete="current-password"
            addon-left-icon="tim-icons icon-key-25"
            placeholder="Verify password" v-model="passwordcheck">
          </base-input>

          <base-button native-type="submit" slot="footer" type="primary" size="lg" round @click.native="login">
            Login
          </base-button>
          <div v-if="loginerr" style="white-space: pre;" class="text-danger">
            {{loginerr}}
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
  import argon2 from 'argon2-browser'; 
  import axios from 'axios';

 export default {
    data() {
      return {
        username: '',
        password: '',
        passwordcheck: '',
        signuperr: '',
        loginerr: '',
        buttnmsg: 'Login',
        readonly: false,
        errors: {
          400: "Your verification code is invalid",
          401: "The user or password entered was invalid",
          403: "Your verification code has expired",
          409: "The account you're trying to sign up for does not match the verification sent",
          428: "You must sign up first.\nPlease check for an IM in Second Life",
          429: "You have made too many attempts to login"
        }
      }
    },
    mounted() {
      if (this.q.a) {
        const data = JSON.parse(atob(this.q.a.split('.')[1]));
        this.username = data['u'];
        this.readonly = true;
        this.buttnmsg = 'Signup';
      }
    },
    watch: {
      passwordcheck: {
        handler(check) {
          let matches = '';
          if (check != this.password) {
            matches = 'Passwords do not match';
          }
          this.loginerr = matches;
          this.signuperr = matches;
        }
      }
    },
    methods: {
      async login(e) {
        e.preventDefault();
        if (this.signuperr != '') return;

        console.time('argon2')
        let hash_param = {
          pass: this.password, 
          salt: `ABNORMALITY:AUTH:SALT`, 
          type: 2,
          // These change whenever we want them to.
          time: 8,
          mem: 16384,
          hashLen: 64,
          parallelism: 8
        }
        const hash = await argon2.hash(hash_param)
        console.timeEnd('argon2')

        const data = {
          user: this.username,
          pass: hash.encoded,
          event: 'cardinal',
          auth: this.q.a,
          site: window.location.host
        }

        let res;
        try {
          res = await axios.post(`${this.api_url}/v2/auth/login`, data, {withCredentials: true});
          if (res.data.id) {
            this.$store.commit('login', res.data.id);
            console.log(`Logged in? ${this.$store.state.logged_in}`);

            if (this.$store.state.url) {
              this.$router.push({ path: this.$store.state.url });
            } else {
              this.$router.push({ name: 'dashboard' });  
            }
            this.$store.commit('url', undefined);
          }
        } catch(err) {
          if (!err.response) return;
          res = err.response;
          if (res.status == 403) { this.q = {}; this.buttnmsg = 'Login' }

          if (res.status == 510) {
            params = res.data.params;
            hash_param.time = params.t;
            hash_param.parallelism = params.p;
            hash_params.mem = params.m;
            try {
              const rehash = await argon2.hash(hash_params);
              data.hash = rehash.encoded;
              res = await axios.post(`${this.api_url}/v2/login`, data);
            } catch(err2) {
              if (!err2.response) return;
              res = err2.response;
            }
          }
          this.loginerr = this.errors[res.status];
        }
      }
    }
  };
</script>
<style></style>
