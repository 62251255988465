<template>
  <div>
    <template>
      <div v-if="info" :key="info" class="row justify-content-center">
        <div class="col-xl-8 col-lg-10">
          <card>
            <template slot="header">
              <h3 class="title purchase">Purchase information</h3>
            </template>

            <div class="row justify-content-center">
              <div class="col-11">

                <div class="row">
                  <div class="col-md-6">
                    <table class="table table-sm">
                      <tbody class="purchase">
                        <tr>
                          <td>From</td>
                          <td class="cap">{{this.mapName(info.payee_name)}}</td>
                        </tr>
                        <tr>
                          <td>To</td>
                          <td class="cap">{{this.mapName(info.recipient)}}</td>
                        </tr>
                        <br />
                        <tr>
                          <td>Status</td>
                          <td>{{this.mapStatus(info.status)}}</td>
                        </tr>
                        <tr>
                          <td>Type</td>
                          <td>{{this.mapType(info)}}</td>
                        </tr>
                        <br />
                        <tr>
                          <td>Seller</td>
                          <td class="cap">{{this.mapName(info.owner)}}</td>
                        </tr>
                        <tr v-if="info.affiliate">
                          <td>Affiliate</td>
                          <td class="cap">{{this.mapName(info.affiliate)}}</td>
                        </tr>
                        <tr>
                          <td>Region</td>
                          <td>{{info.region}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <table class="table table-sm">
                      <tbody class="purchase">
                        <tr>
                          <td>Item Name</td>
                          <td>{{info.item}}</td>
                        </tr>
                        <tr v-if="info.selection != info.item">
                          <td>Selection</td>
                          <td class="cap">{{info.selection}}</td>
                        </tr>
                        <tr>
                          <td>Amount Paid</td>
                          <td>${{info.amount}}L</td>
                        </tr>
                        <br />
                        <tr>
                          <td>Paid On</td>
                          <td>{{new Date(info.p_time*1000).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td>{{this.second_date_name}}</td>
                          <td>{{new Date(info.d_time*1000).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td>Delivery Attempts</td>
                          <td>{{info.attempts}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!--{{info}}-->
              </div>
            </div>
          </card>
        </div>
      </div>
      <div v-else class="row justify-content-sm-center">
        <div class="col-xl-8 col-lg-10">
          <card>
            Loading...
          </card>
        </div>
      </div>

      <div v-if="steps" class="row justify-content-center">
        <div class="col-xl-8 col-lg-10">
          <card>
            <template slot="header">
              <h3 class="title purchase">Delivery Breakdown</h3>

              <div class="row justify-content-center">
                <div class="col-11">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th style="width: 5%"><i class="tim-icons icon-sound-wave"></i></th>
                        <th>Step</th>
                        <th style="width: 15%">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in steps" :key="item.task">
                        <td style="width: 5%"><i class="tim-icons purchase" :class="genIcon(item.icon)"></i></td>
                        <td >{{item.task}}</td>
                        <td style="width: 15%">{{item.msg}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';

  export default {
    data() {
      return {
        info: false,
        complete: false,
        steps: false,
        second_date_name: "Last Attempt"
      }
    },
    mounted() {
      this.fetchData();
    },
    created() {
    },
    watch: {
      info: {
        deep: true,
        handler(info) {
          let steps = this.genSteps(info);

          if (info.selection.substring(0,5) == 'CKEY:') info.selection = info.selection.substring(42);

          let status = {icon: "P", task: "Item delivery", msg: "Pending"}
          if (info.status == 'success') {
            status.icon = "Y"; status.msg = "Delivered"; 
            this.second_date_name = "Delivered On"; this.complete = true;
          }
          if (info.status == 'queued') {status.icon = "P"; status.msg = "Delivering"}
          if (info.status == 'busy') {status.icon = "P"; status.msg = "Delayed"}
          steps.push(status)

          if (info.attempts > 5) {
            status = {icon: "Y", task: "Refunding purchase", msg: "Success"}
            this.second_date_name = "Gave up on"
            if (info.refund == '') {
              status.icon = "P"; status.msg = "Pending"
            }
            else { 
              this.second_date_name = "Refunded on"
              this.complete = true;
            }

            let last = steps.pop(); last.icon = "X"; last.msg = "Failed"
            steps.push(last)
            steps.push(status)
          }
          this.steps = steps;
          console.log(steps);

          if (!this.complete) {
            console.log('Refreshing data in 30s')
            setTimeout(this.fetchData, 30000);
          }
        }
      }
    },
    methods: {
      fetchData() {
        axios.get(`${this.api_url}/v2/pub/purchase/${this.$route.params.id}`)
          .then(response => {
            let info = response.data;
            
            this.info = info;
            console.log(this.info);
          })
      },

      mapType(info) {
        if (info.type == 'item') {
          if (info.payee_name != info.recipient) return 'Gift Purchase'
          return 'Regular Purchase'
        }
        return info.type
      },

      mapStatus(s_type) {
        if (s_type == 'missing') return 'Inventory Missing'
        if (s_type == 'offline') return 'Recipient Offline'
        if (s_type == 'pending') return 'Not Delivering Yet'
        if (s_type == 'unavailable') return 'Warehouse Offline'
        if (s_type == 'pending') return 'Attempting Delivery'
        if (s_type == 'busy') return 'Delivery Delayed'
        if (s_type == 'success') return 'Delivered'
        return s_type
      },

      mapName(name) {
        let parts = name.split(".")
        if (parts[1] == 'resident') parts = [parts[0]]
        return parts.join(' ')
      },

      genIcon(code) {
        if (code == 'Y') return 'icon-check-2';
        if (code == 'X') return 'icon-simple-remove';
        if (code == 'P') return 'icon-refresh-02';
      },

      genSteps(info) {
          let steps = []
          let status = {}

          status = {icon: "Y", task: "Getting ready to deliver", msg: "Ready"}
          if (info.status == 'pending') {status.icon = "P"; status.msg = "Pending"}
          steps.push(status)
          if (['pending'].includes(info.status)) return steps;

          status = {icon: "Y", task: "Pickling Item", msg: "Picked"}
          if (info.type != 'item' && info.selected != '') { 
            status.icon = "P"; status.msg = "Picking"
          }
          if (info.type != 'item') steps.push(status)

          status = {icon: "Y", task: "Ensuring inventory is available", msg: "Available"};
          if (info.status == 'missing') {status.icon = "P"; status.msg = "Missing"}
          if (info.status == 'unavailable') {status.icon = "P"; status.msg = "Offline"}
          steps.push(status)
          if (['missing', 'unavailable'].includes(info.status)) {
            if (info.attempts > 5) status.icon = "X";
            steps.pop(); steps.push(status);
            return steps;
          }

          status = {icon: "Y", task: "Checking if recipient online", msg: "Online"}
          if (info.status == 'offline') {status.icon = "X"; status.msg = "Offline"}
          steps.push(status)

          return steps;
      }
    }
  }
</script>
<style></style>
